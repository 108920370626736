/** @jsx jsx */
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout, { WordPressChildrenContent } from "../components/layout";
import GenericCard from "../components/Helpers/GenericCard";
import styled from "@emotion/styled";
import { colors, screenSize } from "../utils/css/themes";
import Caret from "../utils/images/faq-carot.inline.svg";
import { jsx, css } from "@emotion/react";
import Accordion from "../components/Helpers/Accordion";
import { useUrlParse } from "../utils/useUrlParse";
import { TertiaryNav } from "../components/Helpers/TertiaryNav";
import SEO from "../components/seo";
import DangerousHtml from "../components/Helpers/DangerousHtml";

const EventTemplate = (props: any) => {
  const [toggleOpen, setToggleOpen] = React.useState(0);
  const pageInfo = props.data.wpPage;
  if (!pageInfo.id) {
    return (
      <Layout title={pageInfo.title || "Page Not Found"}>
        <p>Could not find data for page</p>
      </Layout>
    );
  }

  const postId = pageInfo?.id;
  const menuItems = props?.data?.MainMenu?.nodes;

  const GetInvolvedCardsSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem auto 0;
    align-items: center;
    @media (min-width: ${screenSize.smallTablet}) {
      align-items: stretch;
    }
  `;

  const SectionHeader = styled.h3`
    text-transform: uppercase;
  `;

  const GenericCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media (min-width: ${screenSize.smallTablet}) {
      flex-direction: row;
    }
  `;

  const ContentStyle = css`
    padding: 1rem;
    text-align: left;

    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: ${colors.darkGrey};
  `;

  const AccordionTitle = styled.div`
    padding: 1rem 1.5rem;
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.33;
    max-width: 557px;
    text-align: left;
    color: ${colors.blue};
  `;

  const CaretTitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: ${colors.blueGrey}};
  `;

  const getTertiaryPages = (
    menuItems: MenuItem[],
    isRootMenu: boolean = false
  ): TertiaryPage[] | null => {
    // Look for the menuItem for the current page in the menu and save its parentId.
    const currentPageMenuItem = menuItems.find((item: MenuItem) => {
      return item.connectedNode?.node?.id == postId;
    });
    if (!currentPageMenuItem) {
      console.log(
        "Failed to find currentPageMenuItem for current page while building tertiaryNav!",
        "Post/Page Id: " + postId
      );
      return null;
    }
    // Use the parentId to find the parent menu.
    let parentMenu: MenuItem = menuItems.find((item: MenuItem) => {
      return item.id === currentPageMenuItem?.parentId;
    });
    if (!parentMenu) {
      console.log(
        "Failed to find parentMenu for current page while building tertiaryNav!",
        "Post/Page Id: " + postId
      );
      return null;
    }
    if (!isRootMenu && parentMenu.parentId == null) {
      console.info(
        "TertiaryNav not created because parentMenu was found to be a root menuItem, if you'd like to create a tertiary nav anyways, use 'isRootMenu=true' as an option in getTertiaryPages()"
      );
      return undefined;
    }
    // From the list of menuItems in the parentMenu, create list of TertiaryPages
    return parentMenu.childItems?.nodes.map((menuItem: MenuItem) => {
      let tertiaryPage: TertiaryPage = {
        page_title: menuItem.label,
        page_slug: menuItem.path,
      };
      return tertiaryPage;
    });
  };
  let tertiaryPages: TertiaryPage[] = getTertiaryPages(menuItems);

  function generateAccordion() {
    if (
      !pageInfo.generalPageExtraContent ||
      !pageInfo.generalPageExtraContent.accordions
    ) {
      return null;
    }
    return pageInfo.generalPageExtraContent.accordions.map(
      (val: any, index: number) => {
        const title = (
          <React.Fragment>
            <CaretTitleContainer>
              <AccordionTitle>{val.title}</AccordionTitle>
              <Caret
                height={"20"}
                width={"20"}
                style={{
                  transform: index === toggleOpen ? "rotate(-180deg)" : "none",
                  margin: "auto 1rem auto 0",
                }}
              >
                &and;
              </Caret>
            </CaretTitleContainer>
          </React.Fragment>
        );

        return (
          <Accordion
            key={index}
            isOpen={index === toggleOpen}
            setIsOpen={() =>
              index !== toggleOpen ? setToggleOpen(index) : setToggleOpen(null)
            }
            title={title}
          >
            <DangerousHtml>{val.content}</DangerousHtml>
          </Accordion>
        );
      }
    );
  }

  const link = (val: any) => {
    switch (val.pageLinkType) {
      case "Internal Link":
        return useUrlParse(val.pageLink);
        break;
      case "External Link":
        return val.externalPageLink;
        break;
      case "Email":
        return val.emailLink;
    }
  };

  function generateGetInvolvedCards() {
    if (
      !pageInfo.generalPageExtraContent ||
      !pageInfo.generalPageExtraContent.optionalCards
    ) {
      return null;
    }
    return (
      <GetInvolvedCardsSectionContainer>
        <GenericCardContainer>
          {pageInfo.generalPageExtraContent.optionalCards.map(
            (val: any, index: number) => (
              <GenericCard
                key={index}
                linkURL={link(val)}
                linkType={val.pageLinkType}
                external={val.pageLinkType === "External Link"}
                title={val.title}
                description={val.description}
                cardImage={
                  val.image &&
                  val.image.localFile &&
                  val.image.localFile.childImageSharp.gatsbyImageData
                }
              ></GenericCard>
            )
          )}
        </GenericCardContainer>
      </GetInvolvedCardsSectionContainer>
    );
  }

  return (
    <Layout title={pageInfo.title}>
      {tertiaryPages && (
        <TertiaryNav
          tertiaryPages={tertiaryPages}
          DefaultPage={pageInfo.title}
        ></TertiaryNav>
      )}
      <SEO title={pageInfo.title} />

      <DangerousHtml>{pageInfo.content}</DangerousHtml>
      <SectionHeader>
        {pageInfo.generalPageExtraContent &&
          pageInfo.generalPageExtraContent.accordionSectionHeader}
      </SectionHeader>
      <div
        css={css`
          margin-top: 1rem;
          @media (max-width: ${screenSize.largeTablet}) {
            margin: 0 auto;
          }
        `}
      >
        {generateAccordion()}
      </div>
      <SectionHeader>
        {pageInfo.generalPageExtraContent &&
          pageInfo.generalPageExtraContent.cardsSectionHeader}
      </SectionHeader>
      {generateGetInvolvedCards()}
    </Layout>
  );
};

export default EventTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...generalPageQuery
    }
    MainMenu: allWpMenuItem(
      filter: { menu: { node: { name: { eq: "MainMenu" } } } }
    ) {
      ...menuQuery
    }
  }
`;

interface MenuItem {
  id: string;
  parentId?: string;
  childItems: { nodes: MenuItem[] };
  connectedNode?: { node: { id: string } };
  label: string;
  path: string;
}

interface TertiaryPage {
  page_title: string;
  page_slug: string;
}
